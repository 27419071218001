import React from 'react'
import { css } from '../../../styled-system/css'
import { HeaderAndFooterLayout } from '../../layouts/HeaderAndFooter'
import { HeadFC } from 'gatsby'
import SEO from '../../components/SEO'

const MentionsLegales = () => {
  return (
    <HeaderAndFooterLayout>
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          margin: 'auto',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '4rem',
        })}
      >
        <div
          className={css({
            width: '50%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '4rem',
          })}
        >
          <div>
            <h1
              className={css({
                alignItems: 'center',
                textAlign: 'center',
                fontFamily: 'Exo2',
                fontSize: '5rem',
                '@media (max-width: 768px)': {
                  fontSize: '3rem',
                },
              })}
            >
              Mentions Légales
            </h1>
            <h2
              className={css({
                alignItems: 'center',
                fontFamily: 'Exo2',
                fontSize: '3rem',
                marginTop: '1remx',
                marginBottom: '1rem',
                textTransform: 'uppercase',
                '@media (max-width: 768px)': {
                  fontSize: '1.5rem', // change this value
                },
              })}
            >
              Presentation du site
            </h2>
            <p
              className={css({
                fontSize: '1.1rem',
                color: '#343a40',
                '@media (max-width: 768px)': {
                  fontSize: '1.1rem',
                },
              })}
            >
              En vertu de l&apos;article 6 de la loi n° 2004-575 du 21 juin 2004
              pour la confiance dans l&apos;économie numérique, les différents
              intervenants du site dans le cadre de sa réalisation et de son
              suivi :
            </p>
            <div
              className={css({
                marginTop: '2rem',
              })}
            >
              <p
                className={css({
                  fontSize: '1rem',
                  color: '#343a40',
                })}
              >
                <span className={css({ fontWeight: 'bold' })}>
                  Propriétaire
                </span>
                <span
                  className={css({
                    color: '#6e6e73',
                  })}
                >
                  &nbsp;: Digit Universe, 79528957800028, SARL au capital de
                  600€. Adresse : 29 RUE DU COLONEL ARNAUD BELTRAME 59152
                  CHERENG – Téléphone : 06 78 25 85 93
                </span>
              </p>
            </div>
            <div
              className={css({
                marginTop: '2rem',
              })}
            >
              <p
                className={css({
                  fontSize: '1rem',
                  color: '#343a40',
                })}
              >
                <span className={css({ fontWeight: 'bold' })}>
                  Créateur du site
                </span>
                <span
                  className={css({
                    color: '#6e6e73',
                  })}
                >
                  &nbsp;: Digit Universe
                </span>
              </p>
            </div>
            <div
              className={css({
                marginTop: '2rem',
              })}
            >
              <p
                className={css({
                  fontSize: '1rem',
                  color: '#343a40',
                })}
              >
                <span className={css({ fontWeight: 'bold' })}>Webmaster</span>
                <span
                  className={css({
                    color: '#6e6e73',
                  })}
                >
                  &nbsp;: Digit Universe – contact@digituniverse.fr
                </span>
              </p>
            </div>
            <div
              className={css({
                marginTop: '2rem',
              })}
            >
              <p
                className={css({
                  fontSize: '1rem',
                  color: '#343a40',
                })}
              >
                <span className={css({ fontWeight: 'bold' })}>Hébergeur</span>
                <span
                  className={css({
                    color: '#6e6e73',
                  })}
                >
                  &nbsp;: Netlify Inc., 2325 3RD STREET,SUITE 215, SAN
                  FRANCISCO, CA 94107 (USA) – Téléphone : +1 844-899-7312
                </span>
              </p>
            </div>
            <div
              className={css({
                marginTop: '2rem',
              })}
            >
              <p
                className={css({
                  fontSize: '1rem',
                  color: '#343a40',
                })}
              >
                <span className={css({ fontWeight: 'bold' })}>Crédits</span>
              </p>
              <p
                className={css({
                  marginTop: '.5rem',
                })}
              >
                <span
                  className={css({
                    color: '#6e6e73',
                  })}
                >
                  Ces mentions légales sont offertes par digituniverse.fr
                </span>
              </p>
            </div>
            <div
              className={css({
                display: 'flex',
                flexDirection: 'column',
                margin: 'auto',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '4rem',
              })}
            >
              <h2
                className={css({
                  alignItems: 'center',
                  fontFamily: 'Exo2',
                  fontSize: '3rem',
                  marginTop: '1remx',
                  marginBottom: '1rem',
                  textTransform: 'uppercase',
                  '@media (max-width: 768px)': {
                    fontSize: '1.5rem',
                  },
                })}
              >
                Propriété intellectuelle et contrefaçons
              </h2>
              <p
                className={css({
                  color: '#6e6e73',
                  '@media (max-width: 768px)': {
                    fontSize: '1.1rem',
                  },
                })}
              >
                Digit Universe est propriétaire des droits de propriété
                intellectuelle ou détient les droits d&apos;utilisation sur tous
                les éléments accessibles sur le site, notamment les textes,
                images, graphismes, logo, icônes, sons, logiciels.
              </p>
              <p
                className={css({
                  color: '#6e6e73',
                  marginTop: '1rem',
                  '@media (max-width: 768px)': {
                    fontSize: '1.1rem',
                  },
                })}
              >
                Toute reproduction, représentation, modification, publication,
                adaptation de tout ou partie des éléments du site, quel que soit
                le moyen ou le procédé utilisé, est interdite, sauf autorisation
                écrite préalable de : Digit Universe.
              </p>
              <p
                className={css({
                  color: '#6e6e73',
                  marginTop: '1rem',
                  '@media (max-width: 768px)': {
                    fontSize: '1.1rem',
                  },
                })}
              >
                Toute exploitation non autorisée du site ou de l’un quelconque
                des éléments qu’il contient sera considérée comme constitutive
                d’une contrefaçon et poursuivie conformément aux dispositions
                des articles L.335-2 et suivants du Code de Propriété
                Intellectuelle.
              </p>
            </div>
          </div>
        </div>
      </div>
    </HeaderAndFooterLayout>
  )
}

export default MentionsLegales

export const Head: HeadFC = () => (
  <SEO
    title="Mentions Légales - Digit Universe"
    description="Les mentions légales de Digit Universe"
    robotsContent="noindex, nofollow"
  />
)
